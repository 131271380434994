<h4><b>{{ !money_id ? "增加紀錄":"修改紀錄" }}</b></h4><br>

<div *ngIf="errStr.length > 0">
    <div class="card">
        <div class="card-header bg-danger text-white">
            錯誤訊息
        </div>
        <div class="card-body">
            <ul>
                <li *ngFor="let str of errStr">{{str}}</li>
            </ul>
        </div>
    </div>
    <br />

</div>

<div *ngIf="arr">
    <div class="btn-group" role="group" aria-label="Basic example">
        <button type="button" class="btn btn-secondary" *ngFor="let sc of arr"
            (click)="clickShortcut(sc)">{{sc.name}}</button>
    </div>
    <br />
    <br />
</div>

<div class="form-group">
    <label for="exampleInputEmail1">主題</label>
    <select name="subject" class="form-control" [ngModel]="subject" (ngModelChange)="getSelectedValue($event)">
        <option value="-1">請選擇</option>
        <option>食野</option>
        <option>搭車</option>
        <option>買野</option>
        <option>家庭</option>
        <option>奉獻</option>
        <option>家用</option>
        <option>旅行</option>
        <option>學費</option>
        <option>其他</option>
    </select>
</div>

<div class="form-group">
    <label for="exampleInputEmail1">價錢</label>
    <input type="number" class="form-control" [(ngModel)]="price" />
</div>

<div class="form-group">
    <label for="exampleInputEmail1">日期</label>
    <div class="input-group">
        <input class="form-control" name="udate" [(ngModel)]="udate" ngbDatepicker #d="ngbDatepicker">
        <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">請選擇</button>
        </div>
    </div>

</div>

<div class="form-group">
    <label for="exampleInputEmail1">時間</label>
    <input type="time" class="form-control" [(ngModel)]="utime" />
</div>

<div class="form-group">
    <label for="exampleInputEmail1">備註</label>
    <input type="text" class="form-control" [(ngModel)]="remark" />
</div>

<input type="submit" class="btn btn-primary" value="遞交" style="width: 100%;" (click)="submit()">

<br /><br />
<input type="submit" class="btn btn-danger" value="刪除" (click)="delete()" *ngIf="money_id!=null">